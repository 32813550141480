.container{
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.map{
  text-align: center;
}

.vendor {
  max-width: 100%;
  max-height: 100%;
}
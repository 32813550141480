.App {
  text-align: left;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 600px) {

  .App {
    text-align: center;
    font-size: 1.3rem;
  }
}